const sendOtp = async (email,otp,callback) => {
  const form_data = new FormData();

  form_data.append("email", email);
  form_data.append("otp", otp);

  fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/send-otp-email`, {
    method: "POST",
    body: form_data,
  })
    .then((v) => v.json())
    .then((v) => {
  
      // console.log(v); 
      callback()
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export default sendOtp;
