import React from "react";
import homeimg2 from "../../../assets/images/pages/home/homeimg2.png";

const HomeSection4 = () => {
  return (
    <div>
      <div className="container">
        <div className="home_section_4">
          <div className="row">
            <div className="col-md-6">
              <div className="home_section_4_sec1">
                <div className="home_section_4_sec1_div"> 
                <h3 className="home_section_4_text1">Book Your Samgri</h3>
                <h5 className="home_section_4_text2">Delivery in 5-7 Days Guranteed</h5>
                <h5 className="home_section_4_text3">Next Day delivery Available in Indea !</h5>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="home_section_4_sec2">
                <img src={homeimg2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection4;
