import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { usePackageHook } from "../../../../redux/hooks/usePackageHook";
import EditedTextCmp from "../../../components/EditedTextCmp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllPackageAddOns } from "../../../../redux/action";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import globalVariable from "../../../../globalVariable";

const RenderPackageData = ({ packageData, data, allPackageAddOns, setselectedCartPackage }) => {

  const [selectedArray, setselectedArray] = useState([]);

  const getTotalCost = (addOnArray, initialPrice) => {
    const addOnTotal = addOnArray.reduce((total, num) => {
      return total + Number.parseInt(num.price);
    }, 0)
    return addOnTotal + initialPrice;
  }

  const isIncluded = (givenArray, givenObj) => {
    return givenArray.filter((value) => value._id === givenObj._id)
  }

  const handleAddOrRemoveFromSelectedArray = (addOnObj) => {

    let isExist = isIncluded(selectedArray, addOnObj);
    if (isExist.length > 0) {
      let newArray = selectedArray.filter((value) => value._id !== addOnObj._id)
      setselectedArray(newArray);
    } else {
      setselectedArray([...selectedArray, addOnObj])
    }
    setTimeout(() => {

      console.log('====================================');
      console.log(selectedArray);
      console.log('====================================');
    }, 1000)
  }

  return (
    <div className="front_package_inf packageDetails_box_item my-2 ">
      <div className="packageDetails_box_item_item">

        <div className="accordion-item">
          <h2 className="accordion-header" id={`heading_faq_${packageData._id}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse_faq_${packageData._id}`}
              aria-expanded="true"
              aria-controls={`collapse_faq_${packageData._id}`}

            >
              <div className="d-flex justify-content-between w-100" >

                <div>
                  <h5 className="mb-0">
                    {packageData.title}
                  </h5>



                  <p className="packageDetails_box_item_item_description"  >
                    <EditedTextCmp givenState={packageData.description} />
                  </p>


                </div>
                <div>
                  <h5>
                    <b>
                      ₹
                    </b>
                    <b style={{ color: 'rgb(206,14,45)' }}>
                      {/* {packageData.price} */}
                      {getTotalCost(selectedArray, packageData.price)}
                    </b>
                  </h5>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`collapse_faq_${packageData._id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading_faq_${packageData._id}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div  >
                <hr />
                <div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>Number of Pandit: &nbsp;</b> {packageData.numberOfPandit}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>Samagries: &nbsp;</b> {packageData.samagries}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>To be arranged by client: &nbsp;</b> {packageData.toBeArrageByClient}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b style={{ fontWeight: 600 }}>Procedure: &nbsp;</b>
                      <EditedTextCmp givenState={packageData.procedure} />
                    </p>
                  </div>
                  <div>
                    <div>
                      <h5>
                        Add Ons
                      </h5>
                    </div>
                    <div>
                      {allPackageAddOns.map((addOnnValue, addOnIndex) => (
                        <div key={`default-${addOnIndex}`} className="mb-3">
                          <Form.Check // prettier-ignore
                            type={'checkbox'}
                            defaultChecked={isIncluded(selectedArray, addOnnValue).length > 0}
                            onClick={() => { handleAddOrRemoveFromSelectedArray(addOnnValue); data.setspackage('') }}
                            id={`default-${addOnnValue._id}`}
                            label={`${addOnnValue.name}  (₹ ${addOnnValue.price})`}
                          />
                        </div>
                      ))}
                    </div>

                  </div>


                </div>
                <div className="mt-3">
                  <span>

                    <button
                      type="button"
                      onClick={() => {
                        data.setspackage(packageData._id);
                        setselectedCartPackage({
                          package: packageData,
                          addonArray: selectedArray
                        })
                      }}
                      className={`btn packageDetails_box_item_item_book_button ${packageData._id === data.spackage
                        ? "packageDetails_box_item_item_book_button_selected"
                        : ""
                        } `}
                    >
                      {packageData._id === data.spackage ? "Selected" : "Select"}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
};

function PujaModal({ visiblePujaModal, setvisiblePujaModal, data }) {
  const allPackageAddOns = useSelector((state) => state.packageAddOnsReducer)
  const dispatch = useDispatch()
  const { allPackages } = usePackageHook();
  const handleClose = () => setvisiblePujaModal(false);
  const [selectedCartPackage, setselectedCartPackage] = useState({})
  const customerData = useSelector((state) => state.customerDataReducer)
  const navigate = useNavigate();

  const handleMakeEnquiry = () => {
    if (!data.spackage) {
      return alert("Please select an package!");
    }
    data.setvisibleLanguageModal(true);
  };
  const handleBookNow = () => {
    if (!data.spackage) {
      return alert("Please select an package!");
    }

    console.log(selectedCartPackage);
    const formBody = new FormData();
    formBody.append('packageCartNewObj', JSON.stringify(selectedCartPackage))


    fetch(
      `${process.env.REACT_APP_BACKEND_1}/customer/package/cart/add`,
      {
        method: "POST",
        body: formBody,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      }
    ).then((v) => v.json()).then((v) => {
      console.log('====================================');
      console.log(v);
      console.log('====================================');
      if (v.message === 'success') {
        alert("Package added to cart successfully!")
        setvisiblePujaModal(false);
        setTimeout(() => {
          return navigate('/cart');
        }, (1000));
      } else {
        return alert('Package not added! Please try again.')
      }
    }).catch((err) => {
      console.log('====================================');
      console.log(err.message);
      console.log('====================================');
      return alert('Package not added! Please try again.')

    })

    // data.setvisibleDateModal(true);
  };

  useEffect(() => {
    if (allPackageAddOns.length < 1) {
      dispatch(getAllPackageAddOns())
    }
  }, [allPackageAddOns, dispatch])

  return (
    <>
      <Modal fullscreen={true} show={visiblePujaModal} onHide={handleClose}>

        <Modal.Body>
          <form className="container">
            <div className="my-2">
              <h4 style={{ textAlign: 'center', fontWeight: 700 }}>
                {data.puja}
              </h4>
            </div>
            {/* <div className="mb-2 mb-3" >

              <EditedTextCmp givenState={data.description} />


            </div> */}
            <div className="my-2 mb-3" >
              <h4 style={{ fontWeight: 600 }}>
                Package(s)
              </h4>
            </div>
            <div className="mb-3">
              {/* <select
                className="form-select"
                value={data.spackage}
                onChange={(e) => {
                  data.setspackage(e.target.value);
                }}
                required
                aria-label="Default select example"
              >
                <option value="">Select Package</option>
                {allPackages
                  .filter((value) => value.pujaCategory === data.puja)
                  .map((value, index) => {
                    return (
                      <option key={index} value={value._id}>
                        {value.title} | pandit : {value.numberOfPandit} | price
                        : {value.price}
                      </option>
                    );
                  })}
              </select> */}

              <div className="packageDetails_box">
                {/* <div className="row justify-content-center"> */}
                <div className="accordion" id="accordionExample">
                  {allPackages
                    .filter((value) => value.pujaCategory === data.puja)
                    .map((value, index) => {
                      return (
                        <RenderPackageData
                          key={index}
                          packageData={value}
                          data={data}
                          allPackageAddOns={allPackageAddOns}
                          setselectedCartPackage={setselectedCartPackage}
                        />
                      );
                    })}
                  {/* </div> */}
                </div>
                <Modal.Footer style={{ borderTop: 0 }}>
                  <Button variant="secondary" type="button" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handleMakeEnquiry();
                    }}
                    type="button"
                  >
                    Make Enquiry
                  </Button>
                  {customerData.email &&
                    <Button variant="primary" onClick={handleBookNow} type="button">
                      Add To Cart
                    </Button>
                  }
                  {!customerData.email &&
                    <Button variant="primary" onClick={() => { navigate('/login') }} type="button">
                      Login
                    </Button>
                  }
                </Modal.Footer>
              </div>


            </div>


          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PujaModal;








