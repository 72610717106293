import { useState } from "react";
import { Link } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import SendChatCallRequest from "./SendChatCallRequest";

const RenderPandits = ({ value }) => {
  const [rating, setRating] = useState(value.rating);

  const [method, setMethod] = useState('');
  const [show, setShow] = useState(false);
  const openModal = (method) => { setMethod(method); setShow(true); }

  const handleRating = (rate) => {
    let prevRating = rating;
    setRating(rate);
    setTimeout(() => {
      setRating(prevRating);
    }, 10);
  };
  return (
    <div className="col-lg-3 col-md-6 py-2">
      <div className="panditCmp1Item " style={{ height: "100%" }}>
        <Link to={`/pandit/${value._id}`} style={{ height: "100%" }}>
          <div className="panditCmp1Item_innerDiv" style={{ height: "100%" }}>
            <div
              className="panditCmp1Item_borderDiv"
              style={{ height: "100%" }}
            >
              <div
                className="panditCmp1Item_borderDiv_sec1"
                style={{ height: "100%" }}
              >
                <div className="panditCmp1Item_borderDiv_sec1_image_div">
                  <img
                    className="panditCmp1Item_img"
                    src={value.image}
                    alt=""
                  />
                </div>
                <center>
                  <Rating
                    initialValue={rating}
                    onClick={handleRating}
                    size={15}
                  />
                  {/* <span className="panditCmp1Item_borderDiv_sec1_ratingdiv">
                    2913 Ratings
                  </span> */}
                </center>
              </div>
              <div className="panditCmp1Item_borderDiv_sec2">
                <span className="panditCmp1Item_name">{value.name}</span>
                <span>{value.education}</span>
                <span>{value.languages}</span>
                <span>{value.experience}</span>
              </div>
            </div>
          </div>
        </Link>
        <div className="panditCmp1Item_borderDiv_buttonBox">
          <button className="" onClick={() => { openModal('chat') }}>chat</button>
          <button className=" filled" onClick={() => { openModal('call') }}>call</button>
        </div>
      </div>
      <SendChatCallRequest show={show} setShow={setShow} method={method} panditValue={value} />
    </div>
  );
};

export default RenderPandits;
