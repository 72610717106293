import globalVariable from "../globalVariable";
import { decryptToken } from "../helpers/EncryptionHandler";
import Cookies from "js-cookie";

export const VerifyToken = (callback) => {
  const storedToken = Cookies.get("customerToken");
  if (!storedToken) {
    return null;
  }

  const Token = decryptToken(storedToken);

  fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/verifyToken`, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${Token}`,
    }),
  })
    .then((v) => v.json())
    .then((v) => {
      if (v.message === "success") {
        globalVariable.accessToken = Token;
        sessionStorage.setItem("accessToken", Token);
        if (v.data._id) {
          callback();
        }
      } else {
        if (v.detail) {
          console.log(v.detail);
        } else {
          console.log("something went wrong!");
        }
        Cookies.remove("customerToken");
      }
    });
};
