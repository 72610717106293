import React from "react";
import "../assets/css/layout/header.css";
import { Link } from "react-router-dom";
import { useBasicSetup } from "../redux/hooks/BasicSetupHook";
import HomeSection1 from "../pages/main/home/HomeSection1";

import shopImage from "../assets/images/header/shop.png";
import panditImage from "../assets/images/header/pandit.png";

const Header = ({ hideBookingSection }) => {
  const { BasicDetailData } = useBasicSetup();
  return (
    <div className="main_header_element">
      <div className="container">
        <div className=" main_header">
          <div className="main_header_sec1 overflow_formBox">
            <Link to="/">
              <img className="main-logo" src={BasicDetailData.image} alt="" />
            </Link>
          </div>
          <div className=" header_main_sec3 header_main_sec3_hide_div">
            <span>
              <Link to="/products">
                <i className="header_img_icon">
                  <img src={shopImage} alt="" />
                </i>
              </Link>
            </span>
            <span>
              <Link to="/pandits">
                <i className="header_img_icon">
                  <img src={panditImage} alt="" />
                </i>
              </Link>
            </span>
            <span>
              <a href={`tel:${BasicDetailData.phone}`}>
                <i className="fa fa-phone"></i>
              </a>
            </span>
          </div>
          <div className=" header_main_sec3 overflow_formBox">
            <span>
              <Link to="/products">
                <i className="header_img_icon">
                  <img src={shopImage} alt="" />
                </i>
              </Link>
            </span>
            <span>
              <Link to="/pandits">
                <i className="header_img_icon">
                  <img src={panditImage} alt="" />
                </i>
              </Link>
            </span>

            <span>
              <Link to="/wishlist">
                <i className="fa fa-heart"></i>
              </Link>
            </span>
            <span>
              <Link to="/profile">
                <i className="fa fa-user"></i>
              </Link>
            </span>
            <span>
              <Link to="/cart">
                <i className="fa fa-shopping-cart"></i>
              </Link>
            </span>
            <span>
              <a href={`tel:${BasicDetailData.phone}`}>
                <i className="fa fa-phone"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
      {!hideBookingSection &&
        <div>
          {window.location.pathname === "/" ? (
            <HomeSection1 showBackgroundColour={true} />
          ) : (
            <div className="pb-3">
              <HomeSection1 />
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default Header;
