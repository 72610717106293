/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import icon1 from "../../../assets/images/pages/home/section1/Icon1.png";
import icon2 from "../../../assets/images/pages/home/section1/Icon2.png";
import icon3 from "../../../assets/images/pages/home/section1/Icon3.png";

// --------------------------- puja modals --------------------
import PujaModal from "./subCmps/PujaModal";
import LocationModal from "./subCmps/LocationModal";
import LanguageModal from "./subCmps/LanguageModal";
import DateModal from "./subCmps/DateModal";
import { useCustomerDataHook } from "../../../redux/hooks/useCustomerDataHook";
// --------------------------- puja modals --------------------
import { useNavigate } from "react-router-dom";
import globalVariable from "../../../globalVariable";
import { GetCurrentLatLon } from "../../../helpers/GetCurrentLatLon";
import { GetAddressFromLatLon } from "../../../helpers/GetAddressFromLatLon";
import { useStateHook } from "../../../redux/hooks/useStateHook";
import { useCityHook } from "../../../redux/hooks/useCityHook";
import { usePujasHook } from "../../../redux/hooks/usePujasHook";
import { useLanguageHook } from "../../../redux/hooks/useLanguageHook";
import { getDateTimeInStringFormat } from "../../../helpers/date&time_functions/dateTimeFunctions";

const getDateTime = () => {
  const currentDate = new Date();
  const todayDate = currentDate.toLocaleDateString();
  const currentTime = currentDate.toLocaleTimeString();
  return { todayDate, currentTime };
};

const HomeSection1 = ({ showBackgroundColour }) => {
  const { allStates } = useStateHook();
  const { allCities } = useCityHook();
  const { allPujas } = usePujasHook();
  const { allLanguages } = useLanguageHook();
  const { customerData } = useCustomerDataHook();
  const navigate = useNavigate();
  // ----------------------------------------------------------------
  const [pujaCategory, setpujaCategory] = useState("puja");
  const [date, setData] = useState("");
  const [puja, setPuja] = useState("");
  const [spackage, setspackage] = useState("");
  const [language, setLanguage] = useState("");
  const [addressLine2, setaddressLine2] = useState("");

  const [userState, setUserState] = useState("");
  const [city, setCities] = useState("");
  const [address, setAddress] = useState("");
  const [userName, setuserName] = useState("");
  const [anotherMobile, setanotherMobile] = useState("");
  const [addressLine1, setaddressLine1] = useState("");

  const handleGetAddressFromLatLon = (givenAddressData) => {
    const { city, state, neighbourhood } = givenAddressData.address;
    const { display_name } = givenAddressData;
    setaddressLine1(neighbourhood);
    setaddressLine2(display_name);
    setAddress(display_name);

    allStates.forEach((element) => {
      if (
        element.name.toLowerCase().includes(state.toLowerCase()) ||
        state.toLowerCase().includes(element.name.toLowerCase())
      ) {
        setUserState(element.name);
        setTimeout(() => {
          allCities.forEach((element) => {
            if (element.name && city) {
              if (
                element.name.toLowerCase().includes(city.toLowerCase()) ||
                city.toLowerCase().includes(element.name.toLowerCase())
              ) {
                setCities(element.name);
              }
            }
          }, 500);
        });
      }
    });
  };

  const handlePackageModal = () => {
    if (!puja || puja === "") {
      return alert("Please Select puja");
    }
    setvisiblePujaModal(true);
  };

  const handleEnqureNowFunction = () => {
    const { todayDate, currentTime } = getDateTime();
    let form_data = new FormData();
    form_data.append("date", date);
    form_data.append("addressLine2", addressLine2);
    form_data.append("userName", userName);
    form_data.append("anotherMobile", anotherMobile);
    form_data.append("puja", puja);
    form_data.append("language", language);
    form_data.append("spackage", spackage);
    form_data.append("requestDate", todayDate);
    form_data.append("requestTime", currentTime);

    fetch(`${process.env.REACT_APP_BACKEND_1}/guest/save_new-enquiry`, {
      method: "POST",
      body: form_data,
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Enquiry request Sent successfully!");
          if (sessionStorage.getItem("bookNow")) {
            sessionStorage.removeItem("bookNow");
          }
          setvisibleLanguageModal(false);
          setvisiblePujaModal(false);
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.1");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again. 2");
        console.log(err.message);
      });
  };

  const bookNowFunction = () => {
    const { todayDate, currentTime } = getDateTime();
    if (!language || language === "") {
      // setvisiblePujaModal(true);
      return alert("Please Select language");
    }
    if (!anotherMobile || anotherMobile === "") {
      // setvisiblePujaModal(true);
      return alert("Please Select date");
    }
    if (!date || date === "") {
      // setvisibleDateModal(true);
      return alert("Please Select date");
    }
    if (!puja || puja === "") {
      // setvisiblePujaModal(true);
      return alert("Please Select puja");
    }
    if (!spackage || spackage === "") {
      // setvisiblePujaModal(true);
      return alert("Please Select Package");
    }

    if (!customerData.email) {
      sessionStorage.setItem("bookNow", "book");

      sessionStorage.setItem("bookNowData", JSON.stringify(data));

      navigate("/login");
      alert("Please Login to continue...");
    }
    setvisiblePujaModal(true);

    let form_data = new FormData();
    form_data.append("userState", userState);
    form_data.append("city", city);
    form_data.append("address", address);
    form_data.append("date", date);
    form_data.append("addressLine1", addressLine1);
    form_data.append("addressLine2", addressLine2);
    form_data.append("userName", userName);
    form_data.append("anotherMobile", anotherMobile);
    form_data.append("puja", puja);
    form_data.append("language", language);
    form_data.append("spackage", spackage);
    form_data.append("requestDate", todayDate);
    form_data.append("requestTime", currentTime);

    fetch(
      `${process.env.REACT_APP_BACKEND_1}/customer/save_new-occasionRequest`,
      {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      }
    )
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          alert("Occasion request Sent successfully!");
          if (sessionStorage.getItem("bookNow")) {
            sessionStorage.removeItem("bookNow");
          }
          setvisiblePujaModal(false);
          setvisibleDateModal(false);
        } else {
          if (v.detail) {
            alert(v.detail);
          } else {
            alert("Something Went Wrong! Please Try again.1");
          }
        }
      })
      .catch((err) => {
        alert("something went wrong! Please Try again. 2");
        console.log(err.message);
      });
  };

  useEffect(() => {
    setTimeout(async () => {
      if (allCities.length > 0 && allStates.length > 0) {
        if (customerData.addressObject) {
          setuserName(
            `${customerData.addressObject.firstName} ${customerData.addressObject.lastName}`
          );
          setanotherMobile(customerData.addressObject.phone);
        }

        if (!sessionStorage.getItem("locationFetched")) {
          GetCurrentLatLon(GetAddressFromLatLon, handleGetAddressFromLatLon);
          setTimeout(() => {
            sessionStorage.removeItem("locationFetched");
          }, 2000);
          sessionStorage.setItem("locationFetched", "yes");
        }

        if (customerData.email) {
          if (sessionStorage.getItem("bookNow")) {
            if (sessionStorage.getItem("bookNow") === "book") {
              if (sessionStorage.getItem("bookNowData")) {
                const thisFormData = JSON.parse(
                  sessionStorage.getItem("bookNowData")
                );
                setUserState(thisFormData.userState);
                setCities(thisFormData.city);
                setAddress(thisFormData.address);
                setData(thisFormData.date);
                setuserName(thisFormData.userName);
                setanotherMobile(thisFormData.anotherMobile);
                setPuja(thisFormData.puja);
                setspackage(thisFormData.spackage);
                setLanguage(thisFormData.language);
                bookNowFunction();
              }
            }
          }
        }
      }
    }, 1000);

    if (date === '' || !date) {
      setData(getDateTimeInStringFormat())
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData.addressObject, allStates, allCities]);

  // --------------------- modal visibility --------------------
  const [visibleDateModal, setvisibleDateModal] = useState(false);
  const [visibleLocationModal, setvisibleLocationModal] = useState(false);
  const [visibleLanguageModal, setvisibleLanguageModal] = useState(false);
  const [visiblePujaModal, setvisiblePujaModal] = useState(false);

  const handleSetVisibleTrue = (givenState) => {
    givenState(true);
  };
  // --------------------- modal visibility --------------------
  const data = {
    userState,
    setUserState,
    city,
    setCities,
    date,
    setData,
    puja,
    setPuja,
    spackage,
    setspackage,
    language,
    setLanguage,
    address,
    setAddress,
    setvisibleDateModal,
    setvisibleLocationModal,
    setvisibleLanguageModal,
    setvisiblePujaModal,
    userName,
    setuserName,
    anotherMobile,
    setanotherMobile,
    addressLine1,
    setaddressLine1,
    addressLine2,
    setaddressLine2,
  };

  return (
    <div
      // className="pt-2 main_puja_form_backgrounddiv_container"
      style={{ backgroundColor: "rgb(254,249,237)" }}
    >
      {/* =============================================================== */}
      <div style={{ display: 'flex', justifyContent: 'center' }} className="mb-2">
        {/* <span> 
                <select
                  className="form-select"
                  value={pujaCategory}
                  onChange={(e) => {
                    setpujaCategory(e.target.value);
                    sessionStorage.setItem('puja', e.target.value);
                  }}
                  required
                  aria-label="Default select example"
                >
                  <option selected value="">Select Puja</option>
                  {["Puja", "Paath", "Jaap"].map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </span> */}
        {["puja", "paath", "jaap"].map((value, index) => {
          return (
            <span key={index} value={value}>
              <button className={`btn ${value === pujaCategory ? 'btn-warning' : 'btn-success'}  me-4`} style={{ textTransform: 'capitalize' }} onClick={() => { setpujaCategory(value) }}>
                {value}
              </button>
            </span>
          );
        })}
      </div>
      <div style={{ position: "relative" }}>
        {showBackgroundColour && (
          <div className="main_puja_form_backgrounddiv"></div>
        )}

        <div className="container">
          <div
          // className="main_puja_form_hoverDiv"
          >

            <div className="main_puja_form">

              <span>
                {/* <img src={icon1} alt="" /> */}
                <select
                  className="form-select"
                  value={puja}
                  onChange={(e) => {
                    setPuja(e.target.value);
                    sessionStorage.setItem('spackage', e.target.value)
                  }}
                  required
                  aria-label="Default select example"
                >
                  <option value="">Select</option>
                  {allPujas
                    .filter((value) => value.category === pujaCategory)
                    .map((value, index) => {
                      return (
                        <option key={index} value={value.name}>
                          {value.name}
                        </option>
                      );
                    })}
                </select>
              </span>


              <span>
                <img src={icon3} alt="" />
                <select
                  className="form-select"
                  value={language}
                  onChange={(e) => {
                    setLanguage(e.target.value);
                    sessionStorage.setItem('language', e.target.value)
                  }}
                  required
                  aria-label="Default select example"
                >
                  <option value="">Select Language</option>
                  {allLanguages.map((value, index) => {
                    return (
                      <option key={index} value={value.name}>
                        {value.name}
                      </option>
                    );
                  })}
                </select>
              </span>
              <span>
                <img src={icon2} alt="" />
                <input
                  type="datetime-local"
                  required
                  value={date}
                  onChange={(e) => {
                    setData(e.target.value);
                    sessionStorage.setItem('date', e.target.value)
                  }}
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </span>
              <span>
                <img src={icon1} alt="" />
                <button
                  onClick={() => {
                    handleSetVisibleTrue(setvisibleLocationModal);
                  }}
                  className="btn"
                >
                  Location
                </button>
              </span>
              <span className="button_span">
                <button
                  onClick={() => {
                    handlePackageModal();
                  }}
                  className="book_button"
                >
                  Book Now
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* =============================================================== */}
      <PujaModal
        visiblePujaModal={visiblePujaModal}
        setvisiblePujaModal={setvisiblePujaModal}
        data={data}
      />
      <LocationModal
        visibleLocationModal={visibleLocationModal}
        setvisibleLocationModal={setvisibleLocationModal}
        data={data}
      />
      <LanguageModal
        visibleLanguageModal={visibleLanguageModal}
        setvisibleLanguageModal={setvisibleLanguageModal}
        data={data}
        handleEnqureNowFunction={handleEnqureNowFunction}
      />
      <DateModal
        visibleDateModal={visibleDateModal}
        setvisibleDateModal={setvisibleDateModal}
        data={data}
        bookNowFunction={bookNowFunction}
      />
    </div>
  );
};

export default HomeSection1;
