import { SET_CUSTOMER } from "../constants";

const customerData = {};
export const customerDataReducer = (state = customerData, action) => {
  switch (action.type) {
    case SET_CUSTOMER:
      return action.data.data;
    default:
      return state;
  }
};
