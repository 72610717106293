// ------------   redux ---------------------
import { useEffect } from "react";
import { getCustomerData, getWishlistData } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import globalVariable from "../../../../globalVariable";
// ------------   redux ---------------------

// A custom hook for handling wishlist
export function useWishlist() {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.customerDataReducer);
  const wishlist = useSelector((state) => state.wishlistReducer);

  useEffect(() => {
    if (!customerData.email) {
      dispatch(getCustomerData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToWishlist = (productData) => {
    if (customerData.email) {
      const form_data = new FormData();
      form_data.append("product_id", productData._id);
      fetch(`${process.env.REACT_APP_BACKEND_1}/customer/add-wishlist`, {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      })
        .then((v) => v.json())
        .then((v) => {
          if (v.message === "success") {
            dispatch(getWishlistData());
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err.message);
          return false;
        });
      return true;
    } else {
      return false;
    }
  };

  const removeFromWishlist = (productData) => {
    if (customerData.email) {
      const form_data = new FormData();
      form_data.append("product_id", productData._id);
      fetch(`${process.env.REACT_APP_BACKEND_1}/customer/remove-wishlist`, {
        method: "POST",
        body: form_data,
        headers: new Headers({
          Authorization: `Bearer ${globalVariable.accessToken}`,
        }),
      })
        .then((v) => v.json())
        .then((v) => {
          if (v.message === "success") {
            dispatch(getWishlistData());
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err.message);
          return false;
        });
      return true;
    } else {
      return false;
    }
  };

  return { wishlist, addToWishlist, removeFromWishlist };
}
