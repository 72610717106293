import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { usePanditHook } from "../../../../redux/hooks/usePanditHook";
import Header from "../../../../layout/Header";
import Banner from "../../../components/Banner";
import Footer from "../../../../layout/Footer";
import { Rating } from "react-simple-star-rating";
import RenderPandits from "../cmps/RenderPandits";
import useScrollToTop from "../../../../hooks/UseScrollToTop";
import SendChatCallRequest from "../cmps/SendChatCallRequest";
import EditedTextCmp from "../../../components/EditedTextCmp";

const RenderProfile = ({ value }) => {

  const [method, setMethod] = useState('');
  const [show, setShow] = useState(false);
  const openModal = (method) => { setMethod(method); setShow(true); }
  useScrollToTop();

  return (
    <div className="panditProfile_mainContainer my-5 mx-3">
      {/* ======================================================= */}
      <div className="container">
        <div className="profile_section_1_main_container">
          <div className="row panditProfile_sec1  p-3">
            <div className="col-lg-6">
              <div className="panditProfile_sec1_box1">
                <div className="panditProfile_sec1_box1_imgBox">
                  <img src={value.image} alt="" />
                </div>
                <div className="panditProfile_sec1_box1_contentBox">
                  <span className="panditProfile_sec1_box1_contentBox_name">
                    {value.name}
                  </span>
                  <span>{value.education}</span>
                  <span>specialization in {value.specialization}</span>
                  <span>{value.experience}</span>
                  <span>{value.languages}</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="panditProfile_sec1_box2">
                <span className="panditProfile_sec1_box2_ratingspan mb-3">
                  <Rating
                    initialValue={value.rating}
                    handleRating={() => { }}
                    size={20}
                  />
                  <span>{value.rating}</span>
                </span>
                <div className="py-3">
                  <div className="panditProfile_sec1_box2_ratingspan_container">
                    <div className="panditCmp1Item_borderDiv_buttonBox">
                      <button className="" onClick={() => { openModal('chat') }}>chat</button>
                      <button className=" filled" onClick={() => { openModal('call') }}>call</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 p-3">
            <div className="profile_section_1_main_container_aboutmebox">
              <h5>About Me</h5>
              <EditedTextCmp givenState={value.description} />


            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------- */}
      {/* -------------------------------------------------- */}
      <div className="container my-5">
        <div className="profile_section_2_main_container">
          <div className="p-3">
            <div className="row">
              <div className="col-md-2 profile_section_2_sec1 mb-3">
                <h4 className="generalH4Heading">Rating</h4>
                <span className="panditProfile_sec1_box2_ratingspan profile_section_2_ratingBox mb-3">
                  <Rating
                    initialValue={value.rating}
                    onClick={() => { }}
                    size={20}
                  />
                  <span>{value.rating}</span>
                </span>
              </div>
              <div className="col-md-10 profile_section_2_sec2 ">
                <h4 className="generalH4Heading">What Customer Said</h4>
                <div className="row profile_section_2_sec2_wcs-box">
                  <div className="col-md-3">
                    <span className="profile_section_2_sec2_type">
                      Knowledge
                    </span>
                    <div className="profile_section_2_sec2_amount_box">
                      <div
                        className="profile_section_2_sec2_amount"
                        style={{ width: `${value.knowledge}%` }}
                      ></div>
                    </div>
                    <span className="profile_section_2_sec2_as_expected">
                      As expected ({value.knowledge}%)
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span className="profile_section_2_sec2_type">
                      Knowledge
                    </span>
                    <div className="profile_section_2_sec2_amount_box">
                      <div
                        className="profile_section_2_sec2_amount"
                        style={{ width: `${value.Accuracy}%` }}
                      ></div>
                    </div>
                    <span className="profile_section_2_sec2_as_expected">
                      As expected ({value.Accuracy}%)
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span className="profile_section_2_sec2_type">
                      Knowledge
                    </span>
                    <div className="profile_section_2_sec2_amount_box">
                      <div
                        className="profile_section_2_sec2_amount"
                        style={{ width: `${value.Transparency}%` }}
                      ></div>
                    </div>
                    <span className="profile_section_2_sec2_as_expected">
                      As expected ({value.Transparency}%)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------- */}
      {/* ======================================================= */}
      <SendChatCallRequest show={show} setShow={setShow} method={method} panditValue={value} />
    </div>
  );
};

const PanditProfile = () => {
  const { id } = useParams();
  const { allPandits } = usePanditHook();

  return (
    <div>
      <Header />
      <Banner title="Pandit Profile" showItemSlider={false} />
      <div className="container   my-3">
        <h5 className="text-primary  wishlistPage_heading">
          <Link to="/" className="text-decoration-none">
            <u>Home</u>
          </Link>
          <span> | </span>
          <Link to="/pandits" className="text-decoration-none">
            <u>Pandit</u>
          </Link>
          <span> | </span>
          <span>Pandit Profile</span>
        </h5>
      </div>
      {allPandits
        .filter((value) => value._id === id)
        .map((value, index) => (
          <RenderProfile key={index} value={value} />
        ))}
      <div className="container">
        <h4 className="generalH4Heading">Recomended Pandit</h4>
        <div className="panditCmp1Box row">
          {allPandits
            .filter((value, index) => index < 4)
            .map((value, index) => (
              <RenderPandits key={index} value={value} />
            ))}
        </div>
        {/* ---------------------------------------- */}
        <div>
          <center>
            <Link to="/pandits">
              <button className="home_section3_item_view_morebox_button">
                View All <i className="fa fa-long-arrow-right"></i>
              </button>
            </Link>
          </center>
        </div>
        {/* ---------------------------------------- */}
      </div>

      <Footer />

    </div>
  );
};
export default PanditProfile;
