import {
  ABOUTUS,
  BASIC_SETUP,
  BLOGS,
  CART,
  CATEGORIES,
  CITIES,
  CUSTOMER,
  CUSTOMER_PACKAGE_CART,
  FAQS,
  LANGUAGES,
  ORDERS,
  PACKAGE_ADD_ONS,
  PACKAGES,
  PANDITS,
  PRODUCTS,
  PUJAS,
  SELECTED_CATEGORY,
  SET_SELECTED_CATEGORY,
  STATES,
  TESTIMONIALS,
  WISHLIST,
} from "./constants";

export function getProductsList() {
  return {
    type: PRODUCTS,
  };
}

export function getCategoriesList() {
  return {
    type: CATEGORIES,
  };
}
export function getCustomerData() {
  return {
    type: CUSTOMER,
  };
}

export function getWishlistData() {
  return {
    type: WISHLIST,
  };
}

export function getCartData() {
  return {
    type: CART,
  };
}

export function getOrdersData() {
  return {
    type: ORDERS,
  };
}

export function getFaqsList() {
  return {
    type: FAQS,
  };
}

export function getBasicDetail() {
  return {
    type: BASIC_SETUP,
  };
}

export function getTestimonialsList() {
  return {
    type: TESTIMONIALS,
  };
}

export function getPanditList() {
  return {
    type: PANDITS,
  };
}
export function getCitiesList() {
  return {
    type: CITIES,
  };
}
export function getStatesList() {
  return {
    type: STATES,
  };
}
export function getLanguagesList() {
  return {
    type: LANGUAGES,
  };
}
export function getPujasList() {
  return {
    type: PUJAS,
  };
}
export function getSelectedCategory() {
  return {
    type: SELECTED_CATEGORY,
  };
}

export function setSelectedCategory(data) {
  return {
    type: SET_SELECTED_CATEGORY,
    data,
  };
}
export function getAllPackages() {
  return {
    type: PACKAGES,
  };
}
export function getAllBlogs() {
  return {
    type: BLOGS,
  };
}
export function getAllAboutUs() {
  return {
    type: ABOUTUS,
  };
}

export function getAllPackageAddOns() {
  return {
    type: PACKAGE_ADD_ONS,
  };
}

export function getAllCustomerPackageCart() {
  return {
    type: CUSTOMER_PACKAGE_CART,
  };
}
