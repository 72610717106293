import "../../../assets/css/pages/myorders.css";
import React from "react";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
import { Link } from "react-router-dom";
import { useOrders } from "../../../hooks/OrderHook";
import useScrollToTop from "../../../hooks/UseScrollToTop";

const Order = () => {
  const { allOrders } = useOrders();
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="container   my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>My Orders</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container mb-5">
        <div className="row">
          {allOrders.map((value, index) => (
            <div className="col-md-4" key={index}>
              <div className="myorderbox">
                {/* <div>
                  <span className="myorderbox-title"> Order Id : </span>
                  <span className="myorderbox-value">{value._id}</span>
                </div> */}
                <div>
                  <span className="myorderbox-title">Total Products : </span>
                  <span className="myorderbox-value">
                    {value.cartProductsArray.length}
                  </span>
                </div>
                <div>
                  <span className="myorderbox-title">Order Value : </span>
                  <span className="myorderbox-value">
                    {value.orderTotal} Rs
                  </span>
                </div>
                <div>
                  <span className="myorderbox-title">Date : </span>
                  <span className="myorderbox-value">{value.date}</span>
                </div>
                <div>
                  <span className="myorderbox-title">Time : </span>
                  <span className="myorderbox-value">{value.time}</span>
                </div>
                <div>
                  <span className="myorderbox-title">Order Status : </span>
                  <span className="myorderbox-value">{value.status}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* -------------------------- */}
      <Footer />
    </div>
  );
};

export default Order;
