
/* eslint-disable react/prop-types */

import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { usePackageHook } from "../../../redux/hooks/usePackageHook";
// import EditedTextCmp from "../../components/EditedTextCmp";

const handleResponsive = (givenResponsiveArray) => {
    let value = givenResponsiveArray.filter((value) => {
        if (value.resolution.length === 1) {
            if (
                useMediaQuery({
                    minWidth: value.resolution[0],
                })
            ) {
                return value.value;
            }
        } else if (
            useMediaQuery({
                minWidth: value.resolution[0],
                maxWidth: value.resolution[1],
            })
        ) {
            return value.value;
        }
        return false;
    });
    if (value.length > 0) {
        return 100 / value[0].value;
    }
    return 33.3333;
};

const responsiveArray = [
    { resolution: [1200], value: 4 },
    { resolution: [768, 1199], value: 3 },
    { resolution: [480, 768], value: 2 },
    { resolution: [0, 480], value: 1 },
];

// =========================== element Code | start =======================
const RenderElement = ({ value, index }) => (
    <div
        className="react_slider_2l4234nl_HomeSection9"
        id={`${index === 0 ? "start_width_0px_HomeSection9" : ""}`}
        style={{ minWidth: `${handleResponsive(responsiveArray)}%` }}
    >
        <div className="p-3 h-100">
            <div className="packageViewSliderCmp p-3 h-100">
                <center>
                    <h4>
                        <b>
                            {value.title}
                        </b>
                    </h4>

                    <span>
                        <b style={{ color: 'red' }}>
                            Rs. {value.price}
                        </b>
                    </span>
                </center>
                {/* <EditedTextCmp givenState={value.procedure} /> */}

            </div>
        </div>
    </div>
);

const RenderElementInText = (givenValue) => {
    return ` 
      <div class="p-3 h-100">
            <div class="packageViewSliderCmp p-3 h-100">
                <center>
                    <h4>
                    <b>
                        ${givenValue.title}
                        </b>
                    </h4>

                    <span>
                        <b style="color:red;">
                           Rs. ${givenValue.price}
                        </b>
                    </span>
                </center>
                

            </div>
        </div>`;
};
// =========================== element Code | end =======================

const HomeSection9 = () => {
    // const [marginLeft, setMarginLeft] = useState();
    const { allPackages } = usePackageHook();

    const storedData = allPackages;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [childLength, setChildLength] = useState(0);
    const [acData, setAcData] = useState([]);
    const [wait1Sec, setWait1Sec] = useState(true);
    const currentDeviceWidth = handleResponsive(responsiveArray);

    const handleInfinity = () => {
        setWait1Sec(false);
        setTimeout(() => {
            setWait1Sec(true);
        }, 500);
    };

    useEffect(() => {
        const storeDataLength = storedData.length;
        if (storeDataLength > 0) {
            let newstoredData = [];
            if (storeDataLength > 1) {
                newstoredData = [
                    storedData[storeDataLength - 2],
                    storedData[storeDataLength - 1],
                    ...storedData,
                ];
            } else {
                if (storeDataLength > 1) {
                    newstoredData = [
                        storedData[storeDataLength - 1],
                        storedData[storeDataLength - 1],
                        ...storedData,
                    ];
                }
            }

            // newstoredData.push();
            if (newstoredData.length < 7) {
                const loopCount = 8 - newstoredData.length;
                if (loopCount > 0) {
                    let max = 0;
                    for (let i = 0; i < loopCount; i++) {
                        if (!storedData[i]) {
                            if (!storedData[max]) {
                                max = 0;
                            }
                            newstoredData.push(storedData[max]);
                            max++;
                        } else {
                            newstoredData.push(storedData[i]);
                        }
                    }
                }
            }

            setAcData(newstoredData);
        }

        // setTimeout(() => {
        //     setInterval(() => {
        //         const thisInfiniteElement = document.getElementById(
        //             "react_slider_2l4234nl_HomeSection9_right_btn"
        //         );
        //         if (thisInfiniteElement) {
        //             thisInfiniteElement.click();
        //         }
        //     }, 3000);
        // }, 2000);
        // setChildrenArr(parentDiv.childNodes);
        setChildLength(storeDataLength);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPackages]);

    const handleLeft = () => {
        if (!wait1Sec) {
            return;
        }
        // let newDoc = setMarginLeft((prevMargin) => prevMargin + 33.66);
        const parentDiv = document.getElementById(
            "react_slider_2l4234nl_HomeSection9_parent_id"
        );
        parentDiv.children[parentDiv.children.length - 1].remove();

        let prevIdDocument = document.getElementById("start_width_0px_HomeSection9");
        let para = document.createElement("div");
        para.classList.add("react_slider_2l4234nl_HomeSection9");
        para.style.minWidth = `${currentDeviceWidth}%`;
        para.setAttribute("id", "start_width_0px_HomeSection9");
        const thisElementData = storedData[childLength - currentIndex - 1];
        para.innerHTML = RenderElementInText(thisElementData);
        prevIdDocument.removeAttribute("id");
        parentDiv.prepend(para);

        prevIdDocument.style.maxHeight = 0;
        setTimeout(() => {
            prevIdDocument.style.maxHeight = "unset";
        }, 1000);
        prevIdDocument.removeAttribute("id");
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else {
            setCurrentIndex(childLength - 1);
        }
    };

    const handleRight = () => {
        if (!wait1Sec) {
            return;
        }
        const parentDiv = document.getElementById(
            "react_slider_2l4234nl_HomeSection9_parent_id"
        );
        let prevElement = document.getElementById("start_width_0px_HomeSection9");
        let prevElementSibling = prevElement.nextElementSibling;
        prevElementSibling.setAttribute("id", "start_width_0px_HomeSection9");
        prevElementSibling.classList.add("height__0");
        prevElement.remove();
        let para = document.createElement("div");
        para.classList.add("react_slider_2l4234nl_HomeSection9");
        para.style.minWidth = `${currentDeviceWidth}%`;
        const thisElementData = storedData[currentIndex];
        para.innerHTML = RenderElementInText(thisElementData);
        parentDiv.append(para);
        setTimeout(() => {
            prevElementSibling.classList.remove("height__0");
        }, 1000);

        if (currentIndex < childLength - 1) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(0);
        }
    };
    return (
        <div className="container">
            {/* ---------------------------------------- */}
            <div className="sectionHeading4">
                <center>
                    <h4>
                        <b>Our Packages</b>
                    </h4>
                </center>
            </div>
            {/* ---------------------------------------- */}
            {childLength > 0 && (
                <div>
                    <div style={{ width: "100%", overflow: "hidden" }}>
                        <div
                            id="react_slider_2l4234nl_HomeSection9_parent_id"
                            style={{
                                marginLeft: `-${currentDeviceWidth}%`,
                                display: "flex",
                                width: "100%",
                                transition: "1s ease-out",
                            }}
                        >
                            {acData.map((value, index) => {
                                return (
                                    <RenderElement value={value} index={index} key={index} />
                                );
                            })}
                        </div>
                    </div>
                    {/* ============== direction buttons ================= */}
                    <div className="mb-5">
                        <center>
                            <button
                                className="home_section3_item_view_morebox_button"
                                onClick={() => {
                                    handleLeft();
                                    handleInfinity();
                                }}
                            >
                                {'< Previous'}
                            </button>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <button
                                id="react_slider_2l4234nl_HomeSection9_right_btn"
                                className="home_section3_item_view_morebox_button"
                                onClick={() => {
                                    handleRight();
                                    handleInfinity();
                                }}
                            >
                                {'Next >'}
                            </button>
                        </center>
                    </div>
                    {/* ============== direction buttons ================= */}
                    {/* ============== current index ================= */}
                    <center style={{ display: 'none' }}>
                        <div>
                            {storedData.map((value, index) => {
                                return (
                                    <span
                                        key={index}
                                        style={{
                                            display: "inline-block",
                                            backgroundColor: currentIndex === index ? "red" : "black",
                                            height: 10,
                                            width: 10,
                                        }}
                                    ></span>
                                );
                            })}
                        </div>
                    </center>
                    {/* ============== current index ================= */}
                </div>
            )}
        </div>
    );
};

export default HomeSection9;
