// ------------   redux ---------------------
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerData } from "../action";
// ------------   redux ---------------------
export function useCustomerDataHook() {
  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.customerDataReducer);

  const updateCustomerData = () => {
    dispatch(getCustomerData());
  };

  useEffect(() => {
    if (!customerData.email) {
      dispatch(getCustomerData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { customerData, updateCustomerData };
}
