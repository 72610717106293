import React from "react";
import "../assets/css/layout/footer.css";
import { Link } from "react-router-dom";
import { useBasicSetup } from "../redux/hooks/BasicSetupHook";

import home from "../assets/images/footer/Home.png";
import shop from "../assets/images/footer/Shop.png";
import cart from "../assets/images/footer/cart.png";
import account from "../assets/images/footer/account.png";
import { useCategories } from "../redux/hooks/CategoriesHook";
import { useSelectedCategoryHook } from "../redux/hooks/useSelectedCategoryHook";
import useScrollToTop from "../hooks/UseScrollToTop";

const Footer = () => {
  const { BasicDetailData } = useBasicSetup();
  const { allCategories } = useCategories();
  const { updateselectedCategory } = useSelectedCategoryHook();
  useScrollToTop();

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <section>
        <footer id="myFooter-1">
          <div className="b1">
            {/* <h5>About</h5> */}
            <img className="footer_logo" src={BasicDetailData.image2} alt="" />
            <span className="footer-social-icons">
              <a
                href={BasicDetailData.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook fa_center"></i>
              </a>
              <a
                href={BasicDetailData.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter fa_center"></i>
              </a>
              <a
                href={BasicDetailData.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-instagram fa_center"></i>
              </a>
              <a
                href={BasicDetailData.youtubeLink}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-youtube fa_center"></i>
              </a>
            </span>
            <span className="footer-downloadourapp_box">
              <h5>Download Our App</h5>
              <span className="footer-downloadourapp">
                <a
                  href={BasicDetailData.iosAppLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-apple"></i>ios
                </a>
                <a
                  href={BasicDetailData.androidAppLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-android"></i>Android
                </a>
              </span>
            </span>
          </div>

          <div className="b3">
            <h5>Popular Categories</h5>
            <ul>
              {allCategories.map((value, index) => (
                <li
                  key={index}
                  onClick={() => {
                    updateselectedCategory(value._id);
                  }}
                >
                  <Link to="/products">{value.name}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="b4">
            <h5>Customer Services</h5>
            <ul>
              <li>
                <Link to="/products">Products</Link>
              </li>
              <li>
                <Link to="/orders">My Orders</Link>
              </li>
              <li>
                <Link to="/cart">Cart</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/term-and-condition">Our T&Cs</Link>
              </li>
              <li>
                <Link to="/cancellation-and-refund-policy">
                  Cancellation and return policy
                </Link>
              </li>
            </ul>
          </div>

          <div className="b2">
            <h5>Contact us</h5>
            <ul>
              <li>
                <a href={`tel:${BasicDetailData.phone}`}>Call us</a>
              </li>
              <li>
                <a href={`mailto:${BasicDetailData.email}`}>Send mail</a>
              </li>
              <li>
                <address>{BasicDetailData.address}</address>
              </li>
            </ul>
          </div>
        </footer>
        <div className="extraFooterSpace  "></div>
        <div className="copyright_message">
          <h6>Copyright © 2024 Pujanya</h6>
          <h6>
            <Link to="/term-and-condition"> Terms and Conditions </Link>|{" "}
            <Link to="/cancellation-and-refund-policy">Refund Policy </Link>
          </h6>
        </div>
      </section>
      <section className="mobile_navigation_box_container">
        <div className=" py-2">
          <div className="container">
            <div className="mobile_navigation_box">
              <Link
                to="/"
                onClick={() => {
                  handleScrollToTop();
                }}
              >
                <div>
                  <img src={home} alt="" />
                  <span className="mobile_footer_title">Home</span>
                </div>
              </Link>
              <Link
                to="/products"
                onClick={() => {
                  handleScrollToTop();
                }}
              >
                <div>
                  <img src={shop} alt="" />
                  <span className="mobile_footer_title">Shop</span>
                </div>
              </Link>
              <Link
                to="/cart"
                onClick={() => {
                  handleScrollToTop();
                }}
              >
                <div>
                  <img src={cart} alt="" />
                  <span className="mobile_footer_title">Cart</span>
                </div>
              </Link>
              <Link
                to="/profile"
                onClick={() => {
                  handleScrollToTop();
                }}
              >
                <div>
                  <img src={account} alt="" />
                  <span className="mobile_footer_title">Account</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
