import React from "react";

// images ----------------------------------------------------------------
// import img1 from "../../../assets/images/pages/home/homeimg1.png";
import { Link } from "react-router-dom";
import { useSelectedCategoryHook } from "../../../redux/hooks/useSelectedCategoryHook";
import { useCategories } from "../../../redux/hooks/CategoriesHook";
// images ----------------------------------------------------------------

const HomeSection3 = () => {
  const { allCategories } = useCategories();
  const { updateselectedCategory } = useSelectedCategoryHook();

  return (
    <div className="my-5 py-4 home_section3_main_container">
      <div className="container">
        <div className="my-2 home_section3_titleBox">
          <h5 className="home_section3_item_view_morebox_heading text-center">
            Our Deals Category
          </h5>
          <p className="home_section3_item_view_morebox_small_desc text-center">
            view our deals
          </p>
        </div>
        <div className="home_section3">
          {/* clone ------------ */}
          <div className="home_section3_item home_section3_item_left home_section3_item_view_morebox">
            <h5 className="home_section3_item_view_morebox_heading">
              Our Deals Category
            </h5>
            <p className="home_section3_item_view_morebox_small_desc">
              view our deals
            </p>
            <Link to="/products">
              <button className="home_section3_item_view_morebox_button">
                View All <i className="fa fa-long-arrow-right"></i>
              </button>
            </Link>
          </div>

          {/* clone ------------ */}


          {/* clone ------------ */}
          {
            allCategories.filter((value, index) => index < 4).map((value, index) => {
              return (<div className="home_section3_item " onClick={() => {
                updateselectedCategory(value._id);
              }}>
                <Link to="/products" style={{ textDecoration: "none" }}>
                  {/* <p className="home_section3_item_sec1">
          <span>Exclusive</span>
        </p> */}
                  <div className="home_section3_item_sec2">
                    <span style={{ height: 120, width: 120, backgroundImage: `url('${value.image}')`, backgroundPosition: 'center center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}>
                      <img src={value.image} style={{ maxHeight: 120, maxWidth: 120, borderRadius: 5, display: 'none' }} alt="" />
                    </span>
                  </div>
                  {/* <h4 className="home_section3_item_sec3">25% OFF</h4> */}
                  <center>
                    <h6 className="home_section3_item_sec4 mt-3 ">
                      {value.name}
                    </h6>
                  </center>
                </Link>
              </div>)
            })
          }
          {/* clone ------------ */}



        </div>
        <div className="home_section3_item_view_morebox_button_box my-2">
          <button className="home_section3_item_view_morebox_button">
            View All <i className="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeSection3;
