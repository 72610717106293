import Cookies from "js-cookie";
import { encryptToken } from "../../../../../helpers/EncryptionHandler";

const handleCustomerLogin = async (email, successCallback, errorCallback) => {
  const form_data = new FormData();

  form_data.append("email", email);

  fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/login-customer`, {
    method: "POST",
    body: form_data,
  })
    .then((v) => {
      // console.log("====================================");
      // console.log(v);
      // console.log("====================================");
      return v.json();
    })
    .then((v) => {
      if (v.message !== "success") {
        if (v.details) {
          errorCallback(v.details);
        } else {
          errorCallback("something went wrong! Please try again.");
        }
        return null;
      }
      if (!v.token) {
        return errorCallback("Token error! Please try again.");
      }

      const encryptedToken = encryptToken(v.token);
      Cookies.set("customerToken", encryptedToken, { expires: 365 });
      successCallback();
    })
    .catch((err) => {
      errorCallback(err.message);
    });
};

export default handleCustomerLogin;
