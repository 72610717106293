import React, { useState } from "react";
import backgroundImage from "../../../assets/images/dummy/homesection1img2.png";
import "../../../assets/css/pages/authPages.css";
import { Link } from "react-router-dom";
import sendOtp from "./helperFunctions/sendOtp";
import handleCustomerLogin from "./helperFunctions/helperAuthFunctions/handleCustomerLogin";
import useScrollToTop from "../../../hooks/UseScrollToTop";

const Login = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  useScrollToTop();

  const handleResendOtp = () => {
    setIsSendingOtp(false);
    setIsOtpSent(false);
    SendOtpToMe();
  };

  const SendOtpToMe = () => {
    let generatedOtp = Math.floor(100000 + Math.random() * 900000) + "";
    setOtp(generatedOtp);
    setIsSendingOtp(true);
    sendOtp(
      email,
      generatedOtp,
      () => {
        alert("otp sent successfully.");
        setIsOtpSent(true);
        setIsSendingOtp(false);
      },
      () => {
        alert("something Went wrong! Please try again.");
        setIsSendingOtp(false);
      }
    );
  };

  const handleLogin = (email) => {
    const form_data = new FormData();
    form_data.append("email", email);
    fetch(`${process.env.REACT_APP_BACKEND_1}/auth/customer/can-login`, {
      method: "POST",
      body: form_data,
    })
      .then((v) => v.json())
      .then((v) => {
        if (v.message === "success") {
          SendOtpToMe();
        } else {
          if (v.details) {
            alert(v.details);
          } else {
            alert("User did not found! Please sign to create a new account.");
          }
        }
      })
      .catch((error) => {
        console.log(error.message);
        alert("Something went wrong! Please try again.");
      });
  };

  const handleVerifyOtp = () => {
    const handleOnLoginSuccess = () => {
      sessionStorage.setItem("success", "Logged in successfully!");
      alert("Logged in successfully!");
      window.location.pathname = "/";
    };
    const handleOnLoginError = (messageReceived) => {
      alert(messageReceived);
    };
    if (otp === enteredOtp) {
      handleCustomerLogin(email, handleOnLoginSuccess, handleOnLoginError);
    } else {
      alert("otp not verified");
    }
  };

  return (
    <div>
      <div
        className="Login_Screen_backgroundImage"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      <div className="login_screen_box_hoverBox">
        <div className="login_screen_box p-4">
          <h4 className="login_screen_box_heading my-2">Login</h4>
          <div className="login_screen_box_sec1 my-4">
            <p className="login_screen_box_sec1_p1">Glad to see you</p>
            <p className="login_screen_box_sec1_p2">Login to Your account</p>
          </div>

          {!isOtpSent && (
            <div className="login_screen_box_sec2 my-4">
              <label className="login_screen_box_sec2_lable1" htmlFor="">
                Email Id
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="login_screen_box_sec2_input1"
                type="text"
              />
            </div>
          )}
          {isOtpSent && (
            <div className="login_screen_box_sec2 my-4">
              <label className="login_screen_box_sec2_lable1" htmlFor="">
                Enter Otp
              </label>
              <input
                value={enteredOtp}
                onChange={(e) => {
                  setEnteredOtp(e.target.value);
                }}
                className="login_screen_box_sec2_input1"
                type="number"
              />
            </div>
          )}

          <div>
            {!isOtpSent && !isSendingOtp && (
              <button
                className="login_screen_box_button"
                onClick={() => {
                  handleLogin(email);
                }}
              >
                Continue
              </button>
            )}
            {isSendingOtp && <p className="text-warning">Sending Otp ...</p>}
            {isOtpSent && (
              <>
                <button
                  className="login_screen_box_button"
                  onClick={() => {
                    handleVerifyOtp();
                  }}
                >
                  Verify Otp
                </button>
                <span
                  className="text-danger resend-otp-text my-2"
                  onClick={() => {
                    handleResendOtp();
                  }}
                >
                  Resend Otp
                </span>
              </>
            )}
          </div>
          <div className="m-3 login_screen_box_login_signup_link_box">
            <Link to="/" className="login_screen_box_login_signup_link">
              Home
            </Link>
            <span>&nbsp; | &nbsp;</span>
            <Link to="/sign-up" className="login_screen_box_login_signup_link">
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
