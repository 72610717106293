import { SET_CUSTOMER_PACKAGE_CART } from "../constants";

const allcustomerPackageCart = [];
export const customerPackageCartReducer = (state = allcustomerPackageCart, action) => {
    switch (action.type) {
        case SET_CUSTOMER_PACKAGE_CART:
            return action.data.data;
        default:
            return state;
    }
};
