import React from "react";
import Banner from "../../components/Banner";
import { Link, useParams } from "react-router-dom";
import { useBlogHook } from "../../../redux/hooks/useBlogHook";
import Footer from "../../../layout/Footer";
import Header from "../../../layout/Header";
import "../../../assets/css/pages/blogPage.css";
import EditedTextCmp from "../../components/EditedTextCmp";

const RenderBlogs = ({ value }) => {
  return (
    <div className="mb-3">
      <Link to={`/blog/${value._id}`}>
        <div className="blogPage_item row">
          <div className="col-md-3">
            <div
              style={{
                backgroundImage: `url(${value.image})`,
                backgroundSize: "cover",
                height: "100%",
                backgroundPosition: "center",
              }}
            >
              {/* <img style={{ maxWidth: "100%" }} src={value.image} alt="" /> */}
            </div>
          </div>
          <div className="col-md-9">
            <h6 className="blogPage_item_sec1">{value.title}</h6>
            <p className="blogPage_item_sec2">
              <EditedTextCmp givenState={value.shortDesc} />
            </p>
          </div>
        </div>
      </Link>
      <hr />
    </div>
  );
};

const RenderBlogViewCmp = ({ givenData }) => {
  return (
    <div>
      <div>
        <h3>{givenData.title}</h3>
      </div>
      <div>
        <img className="w-100" src={givenData.image} alt="" />
      </div>
      <div className="my-3">
        <h5>
          <EditedTextCmp givenState={givenData.shortDesc} />
        </h5>
        <p>
          <EditedTextCmp givenState={givenData.description} />
        </p>
      </div>
    </div>
  );
};

const BlogView = () => {
  const { allBlogs } = useBlogHook();
  const { id } = useParams();
  return (
    <div>
      <Header />
      <Banner title="Blogs" showItemSlider={false} />
      <div className="container mt-5">
        <div className="container my-3">
          <h5 className="text-primary  wishlistPage_heading">
            <Link to="/" className="text-decoration-none">
              <u>Home</u>
            </Link>
            <span> | </span>
            <span>Blog</span>
          </h5>
        </div>
      </div>
      {/* -------------------------- */}
      <div className="container blog_section">
        <div className="my-5">
          {/* =========================================================== */}
          <div className="accordion" id="accordionExample">
            <div className="row">
              <div className="col-md-9">
                {allBlogs
                  .filter((value) => value._id === id)
                  .map((value, index) => (
                    <RenderBlogViewCmp key={"blogView"} givenData={value} />
                  ))}
              </div>
              <div className="col-md-3">
                {allBlogs
                  .filter((value, index) => index < 8)
                  .map((value, index) => (
                    <RenderBlogs key={index} value={value} index={index} />
                  ))}
              </div>
            </div>
          </div>
          {/* =========================================================== */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogView;
