import React from "react";
import { Link } from "react-router-dom";

import mailImage from "../../../../assets/images/pages/checkout/mailImage.png";
const { useNavigate } = require("react-router-dom");

const OrderPlacedCmp = ({ isOrderPlaced, setIsOrderPlaced }) => {
  const navigate = useNavigate();
  return (
    <div style={{ display: isOrderPlaced ? "block" : "none" }}>
      <div
        style={{
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          backgroundColor: "rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-md-6"
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 0px",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            Your Order <br />
            Placed Successfully
          </h2>
          <img src={mailImage} style={{ width: "200px" }} alt="" />
          <button
            className="btn btn-primary"
            style={{
              backgroundColor: "rgb(106,17,16)",
              border: "none",
              minWidth: "270px",
            }}
            onClick={() => {
              navigate("/orders");
              setIsOrderPlaced(false);
            }}
          >
            Done
          </button>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
              fontWeight: "400",
              marginTop: 10,
            }}
            onClick={() => {
              setIsOrderPlaced(false);
            }}
          >
            Go To Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderPlacedCmp;
