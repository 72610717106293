import { put, takeEvery } from "redux-saga/effects";
import {
  ABOUTUS,
  BASIC_SETUP,
  BLOGS,
  CART,
  CATEGORIES,
  CITIES,
  CUSTOMER,
  CUSTOMER_PACKAGE_CART,
  FAQS,
  LANGUAGES,
  ORDERS,
  PACKAGE_ADD_ONS,
  PACKAGES,
  PANDITS,
  PRODUCTS,
  PUJAS,
  SET_ABOUTUS,
  SET_BASIC_SETUP,
  SET_BLOGS,
  SET_CART,
  SET_CATEGORIES,
  SET_CITIES,
  SET_CUSTOMER,
  SET_CUSTOMER_PACKAGE_CART,
  SET_FAQS,
  SET_LANGUAGES,
  SET_ORDERS,
  SET_PACKAGE_ADD_ONS,
  SET_PACKAGES,
  SET_PANDITS,
  SET_PRODUCTS,
  SET_PUJAS,
  SET_STATES,
  SET_TESTIMONIALS,
  SET_WISHLIST,
  STATES,
  TESTIMONIALS,
  WISHLIST,
} from "./constants";
import globalVariable from "../globalVariable";

function* productsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/products/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_PRODUCTS, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* categoriesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/categories/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_CATEGORIES, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* getCustomerData() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/auth/customer/verifyToken`;
    let storedToken = globalVariable.accessToken;
    if (!storedToken) {
      storedToken = sessionStorage.getItem("accessToken");
    }
    let data = yield fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${storedToken}`,
      }),
    });
    data = yield data.json();
    yield put({ type: SET_CUSTOMER, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getWishlistData() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/customer/get-wishlist`;
    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    });
    data = yield data.json();
    yield put({ type: SET_WISHLIST, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getCartData() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/customer/get-cart`;
    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    });
    data = yield data.json();
    yield put({ type: SET_CART, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* getOrderData() {
  let token = globalVariable.accessToken;
  if (!token) {
    token = sessionStorage.getItem("accessToken");
  }
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/customer/myorders`;
    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });
    data = yield data.json();
    yield put({ type: SET_ORDERS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getAllCustomerPackageCartList() {
  let token = globalVariable.accessToken;
  if (!token) {
    token = sessionStorage.getItem("accessToken");
  }
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/customer/package/cart/get`;
    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });
    data = yield data.json();
    yield put({ type: SET_CUSTOMER_PACKAGE_CART, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* getFaqList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/faqs/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_FAQS, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* getBasicSetupData() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/basic_details/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_BASIC_SETUP, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getTestimonialList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/testimonials/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_TESTIMONIALS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getPanditsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/pandits/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_PANDITS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getStatesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/states/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_STATES, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getCitiesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/cities/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_CITIES, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getLanguagesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/languages/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_LANGUAGES, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getPujasList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/pujas/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_PUJAS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getPackageList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/packages/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_PACKAGES, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getBlogList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/blogs/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_BLOGS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getAboutUs() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/aboutus/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_ABOUTUS, data });
  } catch (error) {
    // console.log(error.message)
  }
}
function* getAllPackageAddOnsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/guest/packageaddons/getAll`;
    let data = yield fetch(url);
    data = yield data.json();
    yield put({ type: SET_PACKAGE_ADD_ONS, data });
  } catch (error) {
    // console.log(error.message)
  }
}

function* SagaData() {
  yield takeEvery(PRODUCTS, productsList);
  yield takeEvery(CATEGORIES, categoriesList);
  yield takeEvery(CUSTOMER, getCustomerData);
  yield takeEvery(WISHLIST, getWishlistData);
  yield takeEvery(CART, getCartData);
  yield takeEvery(ORDERS, getOrderData);
  yield takeEvery(FAQS, getFaqList);
  yield takeEvery(BASIC_SETUP, getBasicSetupData);
  yield takeEvery(TESTIMONIALS, getTestimonialList);
  yield takeEvery(PANDITS, getPanditsList);
  yield takeEvery(STATES, getStatesList);
  yield takeEvery(CITIES, getCitiesList);
  yield takeEvery(LANGUAGES, getLanguagesList);
  yield takeEvery(PUJAS, getPujasList);
  yield takeEvery(PACKAGES, getPackageList);
  yield takeEvery(BLOGS, getBlogList);
  yield takeEvery(ABOUTUS, getAboutUs);
  yield takeEvery(PACKAGE_ADD_ONS, getAllPackageAddOnsList);
  yield takeEvery(CUSTOMER_PACKAGE_CART, getAllCustomerPackageCartList);
}

export default SagaData;
