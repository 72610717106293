import { combineReducers } from "redux";
import { productsReducer } from "./allRedudcers/productsReducer";
import { categoriesReducer } from "./allRedudcers/categoriesReducer";
import { customerDataReducer } from "./allRedudcers/customerDataReducer";
import { wishlistReducer } from "./allRedudcers/wishlistReducer";
import { cartReducer } from "./allRedudcers/cartReducer";
import { orderReducer } from "./allRedudcers/orderReducer";
import { faqReducer } from "./allRedudcers/faqReducer";
import { basicSetup } from "./allRedudcers/basicSetup";
import { testimonialsReducer } from "./allRedudcers/testimonialsReducer";
import { panditReducer } from "./allRedudcers/panditReducer";
import { pujasReducer } from "./allRedudcers/pujasReducer";
import { statesReducer } from "./allRedudcers/statesReducer";
import { citiesReducer } from "./allRedudcers/citiesReducer";
import { languagesReducer } from "./allRedudcers/languagesReducer";
import { selectedCategoryReducer } from "./allRedudcers/selectedCategoryReducer";
import { getAllPackages } from "./action";
import { packageReducer } from "./allRedudcers/packageReducer";
import { blogsReducer } from "./allRedudcers/blogsReducer";
import { aboutusReducer } from "./allRedudcers/aboutusReducer";
import { packageAddOnsReducer } from "./allRedudcers/packageAddOnsReducer";
import { customerPackageCartReducer } from "./allRedudcers/customerPackageCartReducer";

export default combineReducers({
  productsReducer,
  categoriesReducer,
  customerDataReducer,
  wishlistReducer,
  cartReducer,
  orderReducer,
  faqReducer,
  basicSetup,
  testimonialsReducer,
  panditReducer,
  pujasReducer,
  statesReducer,
  citiesReducer,
  languagesReducer,
  selectedCategoryReducer,
  getAllPackages,
  packageReducer,
  blogsReducer,
  aboutusReducer,
  packageAddOnsReducer,
  customerPackageCartReducer
});
