// eslint-disable-next-line no-unused-vars
import React from "react";
import RenderProduct from "./subCmps/RenderProduct";

const ProductCmp1 = ({ AllProducts, AllCategories }) => {
  return (
    <section>
      <div>
        <div className="allProducts row">
          {AllProducts.map((value, index) => {
            return (
              <RenderProduct
                key={index}
                item={value}
                AllCategories={AllCategories}
                show3={true}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductCmp1;
